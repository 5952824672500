<template lang="pug">
div
  template(v-for="(v, i) in entries")
    v-divider(v-if="i")
    v-card-title(v-if="v.title" :class="[backgroundColor(v), textColor(v)]") {{ entryTitle(v) }}
    v-card-text(:class="backgroundColor(v)")
      div(:class="textColor(v)" v-html="entryDescription(v)")
      div
        span.mr-2(v-if="v.url")
          v-icon.mr-1(:class="textColor(v, 'primary')" small) mdi-open-in-new
          a(:class="textColor(v, 'primary')" :href="v.url" target="_blank") Website/網站
        template(v-if="v.video")
          span.mr-2(v-if="'string' === typeof(v.video.youtube)")
            v-icon.mr-1(:class="textColor(v, 'secondary')" small) mdi-youtube
            a(:class="textColor(v, 'secondary')" :href="`https://youtu.be/${v.video.youtube}`" target="_blank") Video/影片
          template(v-if="Array.isArray(v.video.youtube)")
            span.mr-2(v-for="video in v.video.youtube")
              v-icon.mr-1(:class="textColor(v, 'secondary')" small) mdi-youtube
              a(:class="textColor(v, 'secondary')" :href="`https://youtu.be/${video.link}`" target="_blank") {{ video.title }}
</template>

<script>
export default {
  name: "EntryList",

  methods: {
    backgroundColor(entry) {
      if (entry.emphasis) return "secondary";
    },

    entryDescription(entry) {
      let description = entry.description.enUS || entry.description.full.enUS;
      description += "<br>";
      description += entry.description.zhTW || entry.description.full.zhTW;
      return description;
    },

    entryTitle(entry) {
      return entry.title.enUS + " / " + entry.title.zhTW;
    },

    textColor(entry, color) {
      if (entry.emphasis) return "white--text";
      if (color) return `${color}--text`;
    }
  },

  props: ["entries"]
};
</script>
