<template lang="pug">
v-container
  v-row

    v-col: v-card(flat)
      entry-list(:entries="entries")
      v-divider
      v-card-text.grey.lighten-4.text-center: router-link(to="/more") more projects / 更多專案

    v-col(cols="12" md="3")
      v-card.mb-4.secondary(flat): v-card-text.white--text
        | 有興趣加入我們研究團隊的同學們，隨時歡迎填寫申請表或參觀實驗室(電機系館 92691 室)。
      v-card(flat).mb-4
        v-card-title.subtitle-2 Contact / 聯絡方式
        v-card-text
          v-icon.mr-1.primary--text(small) mdi-email-outline
          a(href="mailto:darby@mail.ee.ncku.edu.tw") TH Chang / 老師#[br]
          v-icon.mr-1.primary--text(small) mdi-email-outline
          a(href="mailto:mbilabta@gmail.com") TA / 助教#[br]
      v-card(flat).mb-4
        v-card-title.subtitle-2 Link / 相關連結
        v-card-text.text-left
          a(href="ahdb/") AH-DB / 分子交互作用資料庫#[br]
          a(href="ccprof/") CCProf / 蛋白質形變資料庫#[br]
          a(href="wp/") Web Programming / 網際網路程式設計課程#[br]
          a(href="swd/") Social Web Design / 社群網站設計課程#[br]
      v-card(flat).mb-4
        v-card-title.subtitle-2 Work / 作品
        v-card-text.text-left.
          #[a(href="ahdb/") AH-DB]: collecting protein structure pairs before and after bindingan#[br]
          #[a(href="ccprof/") CCProf]: an intergrated platform for exploring conformational change of proteins#[br]
          #[a(href="dbd2bs/") DBD2BS]: connecting a DNA-binding protein with its binding sites#[br]
          #[a(href="e1ds/") E1DS]: predicting catalytic sites based on 1-dimensional signatures#[br]
          #[a(href="prasa/") PRASA]: an integrated web server that analyzes protein interaction types#[br]
          #[a(href="prote2s/") Prote2s]: predicting protein secondary structures#[br]
          #[a(href="seemotif/") seeMotif]: exploring and visualizing sequence motifs in 3D structures#[br]
          #[a(href="medock/") MEDock]: a docking web server for efficient prediction of protein-ligand binding sites#[br]
          #[a(href="protemot/") Protemot]: predicting protein binding sites with automatically extracted geometrical templates#[br]
          #[a(href="yga/") YGA]: identifying distinct biological features between yeast gene sets#[br]
          #[a(href="ypa/") YPA]: collecting promoter features in #[i Saccharomyces cerevisiae]#[br]
</template>

<script>
import entries from "@/assets/entries.json";
import entryOrder from "@/assets/entry_order.json";
import EntryList from "@/components/EntryList";
export default {
  name: "Home",

  components: { EntryList },

  data: () => ({
    entries: entryOrder.home.map(v => entries[v])
  })
};
</script>
