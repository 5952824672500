<template lang="pug">
v-container
  p: router-link(to="/") &laquo; Back to home / 回首頁
  v-row
    v-col: v-card
      entry-list(:entries="entries")
</template>

<script>
import entries from "@/assets/entries.json";
import entryOrder from "@/assets/entry_order.json";
import EntryList from "@/components/EntryList";
export default {
  name: "Home",

  components: { EntryList },

  data: () => ({
    entries: Object.keys(entries)
      .filter(v => !entryOrder.home.includes(v))
      .map(v => entries[v])
  })
};
</script>
