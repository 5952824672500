<template lang="pug">
v-app(v-scroll="onScroll")
  v-app-bar(app :flat="0 == top" prominent)
    h1.align-self-center.d-flex
      v-img.mr-4(alt="logo" contain src="./assets/mbi-logo.h64.png" transition="scale-transition")
      | Molecular Biomedical Informatics#[br]分子生醫資訊實驗室
    v-spacer
    v-btn(plain to="/")
      v-icon.mr-1 mdi-home-outline
      | Home
    v-btn(href="mailto:darby@mail.ncku.edu.tw" plain)
      v-icon.mr-1 mdi-email-outline
      | Contact
  v-main.my-4: router-view
  v-footer.blue-grey.darken-4.white--text: v-container Copyright &copy; 2006-2021 #[a(href="https://zoro.ee.ncku.edu.tw/").white--text MBI Lab]. All rights reserved.
</template>

<script>
export default {
  name: "App",

  data: () => ({
    top: 0
  }),

  methods: {
    onScroll() {
      this.top = window.scrollY;
    }
  }
};
</script>

<style lang="sass">
*
  font-family: Verdana, "微軟正黑體", sans-serif
  text-align: justify

#app
  background-color: #f1e8d3

.v-toolbar__content
  margin-left: auto
  margin-right: auto
  max-width: 900px

  .v-btn:last-child
    padding-right: 0 !important

header h1
  font-size: 1.25rem
  font-weight: normal
  text-align-last: justify

em
  color: var(--v-secondary-base)
  font-style: normal

.container
  max-width: 900px !important
</style>
